<template>
  <ValidationProvider :name="name" :rules="rules" v-slot="{ errors }">
    <div class="input-wrapper">
      <div
        class="input-field"
        :class="{
          block: isBlock,
        }"
      >
        <div>
          <h2 class="input-list-title mb-0">{{ title }}</h2>
          <date-picker
            :value="value"
            @change="$emit('input', $event)"
            :customText="customText"
            :clearable="false"
            value-type="YYYY-MM-DD"
            format="DD.MM.YYYY"
            :lang="lang"
            :name="name"
            :disabled="isDisable"
            :default-value="defaultValue"
            :disabled-date="disabledBefore"
            :placeholder="$t('selectDate')"
          />
        </div>
      </div>
      <span class="addition-text text-danger" v-if="errors.length > 0">{{errors ? errors[0]: ''}}</span>
    </div>
  </ValidationProvider>
</template>

<script>
import moment from "moment";
export default {
  name: "SelectDate",
  components: {},
  props: {
    isBlock: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    value: {
      type: null,
    },
    until: null,
    name: {
      type: String,
    },
    rules: {
      type: String,
      default: "",
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    isDisabledBeforeToday: {
      type: Boolean,
      default: false,
    },
    customText: {
      type: String,
      default: null,
    },
    defaultValue: null
  },
  data() {
    return {
      date: null,
      tomorrow: null,
      initialValue: null,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
          months: [
            this.$i18n.t('jan'),
            this.$i18n.t('feb'),
            this.$i18n.t('mar'),
            this.$i18n.t('apr'),
            this.$i18n.t('may'),
            this.$i18n.t('jun'),
            this.$i18n.t('jul'),
            this.$i18n.t('aug'),
            this.$i18n.t('sep'),
            this.$i18n.t('oct'),
            this.$i18n.t('nov'),
            this.$i18n.t('dec'),
          ],
          monthsShort: [
            this.$i18n.t('janShort'),
            this.$i18n.t('febShort'),
            this.$i18n.t('marShort'),
            this.$i18n.t('aprShort'),
            this.$i18n.t('mayShort'),
            this.$i18n.t('junShort'),
            this.$i18n.t('julShort'),
            this.$i18n.t('augShort'),
            this.$i18n.t('sepShort'),
            this.$i18n.t('octShort'),
            this.$i18n.t('novShort'),
            this.$i18n.t('decShort'),
          ],
          weekdays: [
            this.$i18n.t('sun'),
            this.$i18n.t('mon'),
            this.$i18n.t('tue'),
            this.$i18n.t('wed'),
            this.$i18n.t('thu'),
            this.$i18n.t('fri'),
            this.$i18n.t('sat'),
          ],
          weekdaysShort: [
            this.$i18n.t('sunShort'),
            this.$i18n.t('monShort'),
            this.$i18n.t('tueShort'),
            this.$i18n.t('wedShort'),
            this.$i18n.t('thuShort'),
            this.$i18n.t('friShort'),
            this.$i18n.t('satShort'),
            ],
          weekdaysMin: [
            this.$i18n.t('sunMin'),
            this.$i18n.t('monMin'),
            this.$i18n.t('tueMin'),
            this.$i18n.t('wedMin'),
            this.$i18n.t('thuMin'),
            this.$i18n.t('friMin'),
            this.$i18n.t('satMin'),
            ],
        },
        monthBeforeYear: false,
      },
      disabledParams: {},
    };
  },
  methods: {
    disabledBefore(date) {

      if (this.isDisabledBeforeToday) {
        return (moment(date).format('YYYY-MM-DD') < moment(this.until).format("YYYY-MM-DD")) || (moment(date) > moment().add('2', 'years'));
      } else {
        return moment(date) > moment().add('2', 'years');
      }
    },
  },
};
</script>

<style lang="scss">
.input-field {
  position: relative;
}

.mx-input-wrapper {
  display: flex;
  justify-content: space-between;
}
.mx-datepicker-content {
  position: relative;
  background: #fff;
  z-index: 10;
}
</style>
